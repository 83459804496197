import React from 'react';
import { Link, graphql } from 'gatsby';

import {
    IntroHolder,
    TwoColumns,
    Sidebar,
    Reference,
    Content,
    IntroQuote,
} from '@bahaide/shared/components/InnerPage';

import InnerPage from '../../../components/InnerPage';

const GemeinsamesLernen = ({ data }) => (
    <InnerPage title="Gemeinsames Lernen" description="">
        <IntroHolder
            title="Gemeinsames Lernen"
            subtitle="Teilnehmen und aktives Mitgestalten in eigener Umgebung"
            picture={data.introImage}
            breadcrumbs={{
                parent: 'Gemeinsames Handeln',
                'current-item': 'Gemeinsames Lernen',
            }}
        >
            <IntroQuote href="/gemeinsames-handeln/gemeinsames-lernen/zitate">
                Der Zugang zu Wissen ist das Recht eines jeden Menschen, und
                mitzuhelfen, Wissen zu generieren, anzuwenden und zu verbreiten
                ist eine Verantwortung, die alle schultern müssen ...
            </IntroQuote>
        </IntroHolder>

        <TwoColumns>
            <Content>
                <p>
                    Der Mensch ist ein lernendes Wesen. Die beständige
                    Weiterentwicklung des eigenen Charakters und der Dienst an
                    der Gesellschaft sind dabei unentbehrlich, wobei wir diesen
                    Weg mit anderen gemeinsam gehen.
                </p>
                <div>
                    <p>
                        Durch Erfahrungsaustausch und Beratung mit anderen
                        können Erkenntnisse aus unserem Handeln reflektiert,
                        miteinander geteilt und weiterentwickelt werden.
                        Aufrichtigkeit, Wertschätzung, Verständnis, gegenseitige
                        Ermutigung und Demut tragen wesentlich zu einer neuen
                        Kultur der Beratung und Zusammenarbeit bei. Dazu gehört,
                        dass wir die Meinungen anderer respektieren, uns über
                        ihre Fortschritte freuen und sie nicht als Konkurrenz
                        verstehen. Lernen in der Gemeinschaft führt immer zu
                        Herausforderungen, die aber als Chance für die
                        Weiterentwicklung sowohl des Einzelnen als auch der
                        Gruppe gesehen werden können.
                    </p>
                    <p>
                        <img
                            alt=""
                            src={
                                data.gemeinsamLernenCollage.childImageSharp
                                    .fluid.src
                            }
                            className="aligncenter"
                        />
                    </p>
                    <p>
                        Um diesen Lernprozess möglichst vielen Menschen zu
                        ermöglichen hat die Bahá’í Gemeinde hierfür ein eigenes
                        „
                        <Link to="/gemeinsames-handeln/gemeinsames-lernen/das-trainingsinstitut/">
                            Trainingsinstitut
                        </Link>
                        “ ins Leben gerufen. Ihr Ziel ist es, Menschen jeglicher
                        Herkunft, Kultur und religiösen Hintergrunds dabei zu
                        unterstützen, die in jedem Menschen angelegten
                        geistig-spirituellen Eigenschaften und Talente zu
                        entwickeln und in Taten umzusetzen.
                    </p>
                </div>
                <Reference>
                    <p>1. Das Universale Haus der Gerechtigkeit, Ridvan 2010</p>
                </Reference>
            </Content>
            <Sidebar />
        </TwoColumns>
    </InnerPage>
);

export default GemeinsamesLernen;

export const pageQuery = graphql`
    query {
        introImage: file(
            relativePath: { eq: "gemeinsames-lernen-feature.jpg" }
        ) {
            ...fluidImage
        }
        gemeinsamLernenCollage: file(
            relativePath: { eq: "GemeinsamLernen.m.png" }
        ) {
            ...fluidImage
        }
    }
`;
